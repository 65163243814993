<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch lg:gap-8'>
    <div
      class='background-style text-white py-4 px-4 lg:py-12 lg:px-8 flex-shrink-0'
      :style='`${backgroundStyle}`'>
      <ul class='sidebar-navigation-links flex flex-col lg:sticky lg:top-24 whitespace-pre-line text-gray-100'>
        <li v-for='(gallery, index) in eventPhotoGallery'
          :key='`gallery-menu-${gallery.galleryId}`'
          @click='selectGallery(index)'
          class='py-1 lg:py-2 px-1 hover:shadow-md cursor-pointer border border-transparent hover:border-gray-300 rounded-md mb-2 lg:mb-4'
          :class='isSelectedGallery(index)'>
          <div class='text-sm lg:text-noraml uppercase flex-grow lg:w-full'>{{gallery.dateString}}</div>
          <div class='text-xs lg:text-sm lg:w-full text-left'>{{gallery.dateDescription}}</div>
        </li>
      </ul>
    </div>
      
    <div class='flex-grow px-4 lg:px-0 relative iframe-flex-container'>
      <h1 class='text-lg lg:text-2xl font-semibold text-gray-800 mt-4 lg:mt-12 lg:pl-6'>{{selectedGallery.dateString}}</h1>
      <iframe 
        loading='lazy' 
        :src='galleryUrl'
        frameborder='0' 
        allow='autoplay;' 
        class='z-0 iframe-google-images'
        style=''></iframe>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PhotoGallery',
  data () {
    return {
      selectedGalleryIndex: 0,
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'eventPhotoGallery',
    ]),
    backgroundStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    selectedGallery () {
      return this.eventPhotoGallery[this.selectedGalleryIndex]
    },
    galleryUrl () {
      return `https://drive.google.com/embeddedfolderview?id=${this.selectedGallery.galleryId}#grid`
    },
  },
  methods: {
    selectGallery (index) {
      this.selectedGalleryIndex = index
    },
    isSelectedGallery (index) {
      return (this.selectedGalleryIndex === index) ? 'font-semibold opacity-100' : 'font-normal opacity-70'
    },
  }
}
</script>

<style type='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.iframe-flex-container {
  min-height: 100vh;
}
.iframe-google-images {
  @apply absolute;
  transform: scale(0.5);
  width: 180vw;
  left: -11rem;
  top: -8rem;
  height: calc(100%); 
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }

  .iframe-flex-container {
    min-height: calc(100vh - 4rem);
  }
  .iframe-google-images {
    @apply static;
    transform: scale(1);
    width: 100%;
    height: calc(100% - 6rem); 
    min-height: calc(100vh - 8rem);
  }
}

</style>
